<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <WindowTitleBar title="Knitted Fabric Reconciliation"  @loadData="loadStockOnDate" @close_window="closeThis" >{{setTitle()}}</WindowTitleBar>

      <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Reconcilater</label>
            <select id="txtRefNo" class="form-control select" autofocus="" required="" v-if="voucher"  v-model="voucher.emp_id"  >
              <option v-for="emp in ledgers" v-bind:value="emp.id">
                {{ emp.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
          </div>
        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Ref No</label>
            <input type="text" class="form-control" placeholder="Ref No" minlength="0" maxlength="30" v-if="voucher" v-model="voucher.ref_no" >
          </div>
        </div>


        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Recon Date</label>
            <input  id="dtpDate"  type="date" class="form-control" v-if="voucher" v-model="voucher.rec_date" placeholder="Recon Date" @change="loadStockOnDate"  >
          </div>
        </div>

      </div>

      <div class="row">
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Knitted Fabric</th>
              <th style="width:150px; text-align: right;">System Stock</th>
              <th style="width:150px; text-align: right;">Actual Stock</th>
            </tr>

            </thead>
            <tbody v-if="voucher" v-for="(detail,index) in voucher.list" >
              <tr style="padding: 0px;">
                <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

                <td style="padding: 0px;">
                      {{ detail.item.name }}
                </td>

                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  placeholder="0.00" v-if="detail" v-model="detail.sys_qty" disabled readonly />
                </td>

                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.act_qty" @change="sum_actual_stock" />
                </td>

              </tr>
            </tbody>
            <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td class="text-right" ><span style="padding-right: 0px;">{{ sys_stock_total | formatWeight }}</span></td>
              <td class="text-right" ><span style="padding-right: 0px;">{{ act_stock_total | formatWeight }}</span></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>



      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">

        </div>

        <div class="col-md-3 text-right">
          <div class="form-group row">

          </div>

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
<!--          <button id="btnclose" type="button" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>-->
        </div>
      </div>
    </div>
    </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import numeral from 'numeral'
  import { store } from '@/store/store.js'
  import WindowTitleBar from '../../../components/core/WindowTitleBar'

  export default {
    name: 'knit_raw_fabric',
    components: {
      WindowTitleBar
    },
    store,
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2000,"rec_date":"0001-01-01","rec_no":0,"ref_no":"","ref_date":"0001-01-01","emp_id":0,"employee":{"id":0,"type":0,"name":"","street":"","city":"","pin":""},"remarks":"","list":[]}')
      }
    },
    beforeMount () {
      const self = this;
      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      if( this.myvoucher.id > 0 ) {
        let data = [];
        const request = async () => {
          this.voucher = this.myvoucher;
          this.sum_actual_stock();
        }
        request();
      }
    },
    data () {
      return {
        readonly: false,
        ledgers:[],
        quality_id : 0,
        color_id : 0,
        sys_stock_total : 0.0,
        act_stock_total : 0.0,
        voucher: JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2000,"rec_date":"0001-01-01","rec_no":0,"ref_no":"","ref_date":"0001-01-01","emp_id":0,"employee":{"id":0,"type":0,"name":"","street":"","city":"","pin":""},"remarks":"","list":[]}'),
      }
    },
    created () {
      const self = this;

      self.$data.voucher.rec_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self = this;

      self.loadEmployees();

      if (self.$data.voucher.rec_date == '0001-01-01' || self.$data.voucher.rec_date == '2000-01-01'){
        self.$data.voucher.rec_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      }

      self.loadStockOnDate();

      $('#txtRefNo').focus();
    },
    filter:{
      formatWeight(val){
        return val.toFixed(3);
      },
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
    },
    methods:{
      setTitle() {
        return this.voucher.id == 0 ? "Knitted Raw Fabric Reconciliation" : "Doc No: " + this.voucher.doc_no + "  Knitted Raw Fabric Reconciliation Updation";
      },
      indianFormat(val){
        return parseFloat(val).toFixed(3);
      },
      closeThis(){
        if(this.voucher.id > 1){
          this.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2000,"rec_date":"0001-01-01","rec_no":0,"ref_no":"","ref_date":"0001-01-01","emp_id":0,"employee":{"id":0,"type":0,"name":"","street":"","city":"","pin":""},"remarks":"","list":[]}');
        }else{
          this.$router.push("/home")
        }
      },
      addRow(){
        try {
          const self = this;
          self.$data.voucher.list.push(JSON.parse('{"id":0,"master_id":0,"item":{"id":0,"name":"","p_name":""},"item_id":0,"sys_qty":0,"sys_weight":0,"act_qty":0,"act_weight":0,"sto_qty":0,"sto_weight":0}'));

          setTimeout(function(){
            $("tbody>tr").last().find("td:eq(1) select").focus();
          }, 99);

        }catch (e) {
          alert(e);
        }
      },
      onrow_change(rowIndex, colIndex) {
        const self = this;
        let tax_value = 0;

        let detail = self.$data.voucher.list[rowIndex];
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.act_stock_total += parseFloat(detail.act_qty);
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sum_actual_stock();
        }
      },
      sum_actual_stock() {
        const self = this;

        self.$data.act_stock_total = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.act_stock_total += parseFloat(detail.act_qty);
        });
      },
      clear(){
        $('#btnSave').prop('disabled', false);
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2000,"rec_date":"0001-01-01","rec_no":0,"ref_no":"","ref_date":"0001-01-01","emp_id":0,"employee":{"id":0,"type":0,"name":"","street":"","city":"","pin":""},"remarks":"","list":[]}');
        self.$data.voucher.list = [];
        self.$data.act_stock_total = 0.0;
        self.$data.sys_stock_total = 0.0;
        self.$data.voucher.rec_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

        $('#txtRefNo').focus();
      },
      loadEmployees() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock();
          if(resp.ok) {
            if(_.isArray(resp.data)) {
              self.ledgers = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          $('#mycard').unblock();
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      loadStockOnDate () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        let cur_date = moment(self.$data.voucher.rec_date).format('YYYY-MM-DD');
        self.voucher.list = [];

        // alert(`${process.env.VUE_APP_ROOT_API}api/report/stock/knitrawfabric?cmp_id=${self.$store.state.user.company.id}&from=${cur_date}&upto=${cur_date}&color=0`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/report/stock/knitrawfabric?cmp_id=${self.$store.state.user.company.id}&from=${cur_date}&upto=${cur_date}&color=0`,requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock();
          if(resp.ok) {

            if(_.isArray(resp.data)) {

              self.sys_stock_total = 0;
              self.act_stock_total  = 0;

              resp.data.forEach( (det) => {

                let detail = {"id":0,"master_id":0,"item":{"id":0,"name":"","type":""},"item_id":0,"sys_qty":0,"sys_weight":0,"act_qty":0,"act_weight":0,"sto_qty":0,"sto_weight":0};

                let stock = parseFloat(det.weight);

                detail.item.id = det.item_id;
                detail.item_id = det.item_id;
                detail.item.name = det.quality + " - " + det.color;
                // detail.item.type = det.type;

                detail.sys_qty = stock;
                detail.act_qty = stock;

                self.voucher.list.push(detail);

                if (typeof stock === 'number' && !_.isNaN(stock) ) {

                  self.sys_stock_total += parseFloat(det.weight);
                  self.act_stock_total  += parseFloat(det.weight);

                }

              });

            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
            $('#mycard').unblock();
            swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      saveVoucher(){
        const self = this;

        try{
          $('#mycard').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));
          myvoucher.finyear = self.$store.state.user.finyear;
          myvoucher.status = "Active";
          myvoucher.type = 3;
          myvoucher.cmp_id = self.$store.state.user.company.id;
          myvoucher.rec_date = moment(self.$data.voucher.rec_date).format('YYYY-MM-DD');
          myvoucher.ref_date = moment(self.$data.voucher.rec_date).format('YYYY-MM-DD');

          myvoucher.list.forEach(function (detail){

            detail.sys_qty = parseFloat(detail.sys_qty);
            if (typeof detail.sys_qty !== 'number' || _.isNaN(detail.sys_qty)  ) {
              detail.sys_qty = 0;
            }


            detail.sys_weight = parseFloat(detail.sys_weight);
            if (typeof detail.sys_weight !== 'number' || _.isNaN(detail.sys_weight)  ) {
              detail.sys_weight = 0;
            }

            detail.act_qty = parseFloat(detail.act_qty);
            if (typeof detail.act_qty !== 'number' || _.isNaN(detail.act_qty)  ) {
              detail.act_qty = 0;
            }

            detail.act_weight = parseFloat(detail.act_weight);
            if (typeof detail.act_weight !== 'number' || _.isNaN(detail.act_weight)  ) {
              detail.act_weight = 0;
            }

            // detail.sto_qty = detail.sys_qty - detail.act_qty;
          });


          const requestOptions = {
            method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT' ),
            mode:'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(myvoucher)
          };

          // alert(`${process.env.VUE_APP_ROOT_API}api/reconciliation`);
          $('#btnSave').prop('disabled', true);
          fetch(`${process.env.VUE_APP_ROOT_API}api/reconciliation`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok){
              self.clear();
              swal({title: 'Great', type: 'success', text: resp.msg, onClose: () => {
                $('#txtRefNo').focus();
              }, timer: 1500  });

              $('#mycard').unblock();
              $('#txtRefNo').focus();

              self.$emit('reconciliation_saved', resp.data);
            }else{
              $('#btnSave').prop('disabled', false);
              $('#mycard').unblock();
              swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
            }
          }).catch(function (err) {
            $('#mycard').unblock();
            $('#btnSave').prop('disabled', false);
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
          }).finally(()=>{
            $('#btnSave').prop('disabled', false);
            $('#mycard').unblock();
          });

        }catch (e) {

        }finally {

        }



      },
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
